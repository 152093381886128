html {
  height: 100%;
}
body {
  min-height: 100%;
  background-color: #222;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
  margin: 0;
}
